import { createRouter, createWebHistory } from "vue-router";
import Index from "./components/IndexPage/IndexPage.vue";
import UserProfile from "./components/UserProfile/UserProfile.vue";
import Contact from "./components/ContactUs/ContactUs.vue";
import clearData from "./components/UI/ClearSocial.vue";
import NotFound from "./components/UI/NotFound.vue";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy.vue";
import TermOfService from "./components/Footer/TermOfService.vue";
import RefundPolicy from "./components/Footer/RefundPolicy.vue";
import MvTheatre from "./components/MvTheatreList/TheatreList.vue";
import MvList from "./components/movielist/MovieList.vue";
import MovieDetails from "./components/MovieDetails/MovieDetails.vue";








const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name:'home',
            components:{default:Index},

        },
        {
            name: "index",
            path: "/index",
            components: { default: Index },
        },
        {
            name: "MovieDetails",
            path: "/movie/:slug",
            components: { default: MovieDetails },
        },
        {
            name: "contact",
            path: "/contact",
            components: { default: Contact },
        },
        
        {
            name: "profile",
            path: "/profile",
            components: { default: UserProfile },
        },
        
        {
            name: "clear",
            path: "/clear",
            components: { default: clearData },
        },
        {
            path: '/termofservice',
            name:'termofservice',
            components:{default:TermOfService},

        },
        {
            path: '/refundpolicy',
            name:'refundpolicy',
            components:{default:RefundPolicy},

        },
        {
            path: '/privacypolicy',
            name:'privacypolicy',
            components:{default:PrivacyPolicy},

        },
        {
            path: "/movie-stations/:slug",
            name:'mvtheatre',
            components:{default:MvTheatre},

        },
        {
            path: "/movies/:slug",
            name:'mvlist',
            components:{default:MvList},

        },
        {   
            name: "notfound",
            path: '/:notFound(.*)',
            components: { default: NotFound },
        },
        /*{   
            name: "sample",
            path: '/sample',
            components: { default: Try },
        }*/
       
    ],


    linkActiveClass: 'active',
});
export default router;