<template>
<SiteProgress></SiteProgress>
<SiteHeader @locUpdated="locUpdated" ref="headCmp"></SiteHeader>
<IndexMovies @locUpdated="bnlocUpdated" ref="hederComp" ></IndexMovies>
<IndexTheatres @locUpdated="locUpdated" ref="theatreComp"></IndexTheatres>
<IndexComingSoon ></IndexComingSoon>
<SiteFooter></SiteFooter>
</template>
<script>
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from '../UI/SiteProgress.vue';
import IndexTheatres from './IndexTheatres.vue';
import IndexMovies from './IndexMovies.vue';
import IndexComingSoon from './IndexComingSoon.vue';
import SiteFooter from '../UI/SiteFooter.vue';



export default {
    data:()=>({
        locChanged:false,
    }),
    components:{
        IndexTheatres,
        IndexMovies,
        IndexComingSoon,
        SiteFooter,
        SiteHeader,
        SiteProgress
    },
    methods:{
        locUpdated(){
            this.locChanged=true;
            this.$refs.hederComp.getData();
            this.$refs.hederComp.locations=this.$refs.headCmp.locations;
            this.$refs.theatreComp.getTheatres();
            //this.$refs.bannerComp.isSelected(localStorage.getItem("locCode"));
            },
            bnlocUpdated(){
            this.locChanged=true;
            this.$refs.hederComp.getData();
            this.$refs.theatreComp.getTheatres();
            /*this.$refs.hederComp.locations=this.$refs.headCmp.locations;
            this.$refs.theatreComp.getTheatres();*/
            }

    }
 


}
</script>


