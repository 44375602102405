<template>
  <SiteProgress></SiteProgress>
  <SiteHeader></SiteHeader>
  <div class="container small" style="background-color: #05092b;min-height:550px;">
    <section class="content-section padtb-hed">
      <div class="container-fluid contbg">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h1 class="texh3 text-center" >
              Social Media Cleaner
            </h1>
            <div class="col-md-12 nopad">
              <h4>All you Facebook Data Got Purged</h4>
            </div>
          </div>
          
        </div>
      </div>

      <!-- end container -->
    </section>
  </div>
  <!-- !Mint Top -->

  <!-- !Mint Box -->

  <!-- !NFT Categories -->

  <!-- !Similar Items -->

  <!-- ==================== Start feat ==================== -->

  <!-- ==================== End feat ==================== -->

  <!-- ==================== Start explore ==================== -->

  <!-- ==================== End explore ==================== -->

  <SiteFooter></SiteFooter>
</template>

<script>

import SiteFooter from "./SiteFooter.vue";
import SiteHeader from './SiteHeader.vue';
import SiteProgress from "./SiteProgress.vue";


export default {
  components: {
    SiteFooter,
    SiteHeader,
    SiteProgress,
  },
};
</script>
