<template>
    
<section class="feat-card section-padding" style="background-color: #041525;" data-scroll-index="1" >
<div class="container">

  <div class="row"><div class="col-12 xsnopad10"><div class="sec-head-bord mb-10"><div class="container-fluid"><div class="row"><div class="col-12 rest flex md-no-flex"><div class="sec-head mr-20 md-mb30 mar15"><h4 style="color:#fff;">
    {{}} Theatres  </h4></div></div></div></div></div></div></div>
  
   


<div> 
  
 
  <div class="row">


    <div class="col-md-12" v-if="Theatres">
        <div class="ceb-item-style-2" v-for="(thtr,index) in Theatres" :key="index">
<div class="col-md-1 disno"> <img v-if="thtr.img" :src="uploads + thtr.img" :alt="thtr.sname"> </div>
<div class="col-xs-12 col-md-11">
<div class="ceb-infor">
<div><a href="#">{{thtr.sname}}</a></div>
<span>{{thtr.address}}</span>
<div>

<a href="#"  class="item item-2 yellowbtn" @click="$router.push('/movies/'+thtr.slug)"> book tickets</a>


</div>

<!-- <p>Dan Stevens was born at Croydon in Surrey on 10th October 1982. His parents are teachers. He was educated at Tonbridge School and trained in acting at the National Youth Theatre of Great Britain... </p> -->
</div></div>
</div>
    </div>


  </div>

  </div>






<!-- SHOWTIME ENDS -->

</div>
</section>


</template>

<script>
import axios from 'axios';
import { URL,UPLOADS } from '@/Conf';
// import DatePaginator from '../MovieDetails/DatePaginator.vue';

export default {
    data:()=>({
        isLoading:true,
        showtimeLoader:true,
        currentTab:0,
        uploads:UPLOADS,
        showModal:false,
        warnigText:null,
        Theatres:{},
        locid:localStorage.getItem("locid")
    }),
    computed:{

    },
    updated() {
      
  },
  mounted(){
    this.getTheatres();

  },
  // components:{DatePaginator},

    methods:{
      async getTheatres() {
      axios.get(URL + "theatres/"+localStorage.getItem("locCode")).then((response) => {
        this.Theatres = response.data.result;
      })
    }
      },
    
}
</script>