<template>
<PWAPromptAndro/>
<PWAPromptios :copyBody="'Add Catchmyseat to your homescreen'" />

<header id="main-header" style="                                                                                                                                                                             ;">
<div class="main-header">
<div class="container">
<div class="row">
<div class="col-sm-12">
<nav class="navbar navbar-expand-lg navbar-light p-0">
<button
class="navbar-toggler collapsed"
type="button"
@click="menuOpend=!menuOpend"
>
<font-awesome-icon icon="fa-solid fa-bars" />
</button>

<router-link class="navbar-brand" to="/index">

<img class="img-fluid logo" :src="imgPath + 'assets/img/logo.png'" /> 
</router-link>
<div class="mobile-more-menu">

<li class="nav-item wallet" v-if="!customer.loginCode">
<a
class="nav-link metaportal_fn_button"
href="#"
@click="logSelector = true;menuOpend=false;"
>
<img :src="imgPath + 'assets/img/user.jpg'" class="img-fluid avatar-40 rounded-circle">
</a>
</li>
<li class="nav-item wallet" v-if="customer.loginCode">
<a
class="nav-link metaportal_fn_button"
href="#"
@click="$router.push('/profile')"
>
<img :src="imgPath + 'assets/img/user.jpg'" class="img-fluid avatar-40 rounded-circle">
</a>
</li>

</div>


<div :class="menuOpend===true?'collapse navbar-collapse show':'collapse navbar-collapse'" id="navbarSupportedContent">
<div class="menu-main-menu-container">
<ul id="top-menu" class="navbar-nav ml-auto">
<li class="menu-item active">
<router-link to="/index" @click="menuOpend=false;menuClicked()" class="nav-link" data-scroll-nav="0"
>Home</router-link
>
</li>
<li class="menu-item">
<router-link to="/index" @click="menuOpend=false" class="nav-link" data-scroll-nav="1"
>Movies</router-link
>
</li>
<li class="menu-item">
<router-link to="/index" @click="menuOpend=false" class="nav-link" data-scroll-nav="2"
>Coming Soon</router-link
>
</li>
<li class="menu-item">
<router-link to="/contact" @click="menuOpend=false" class="nav-link" data-scroll-nav="5"
>Contact Us</router-link
>
</li>

</ul>
</div>
</div>

<div class="navbar-right menu-right">
<ul class="d-flex align-items-center list-inline m-0">

<li class="nav-item wallet" v-if="!customer.loginCode" >
<a 
class="nav-link metaportal_fn_button"
href="#" 
@click="logSelector = true;menuOpend=false;"
> 
LOG IN
</a>
</li>

<li class="nav-item wallet" v-if="!customer.loginCode">
<a style="background-color: #ff5800;
padding: 9px 25px;
border-radius: 20px;
text-transform: uppercase;
font-size: 13px;cursor:pointer"
class="nav-link metaportal_fn_button"
@click="locSelector=!locSelector;menuOpend=false;"
> 
Select City
</a>
</li>
<li class="nav-item wallet" v-if="customer.loginCode">
<a
class="nav-link metaportal_fn_button"
href="#"
@click="$router.push('/profile')"
>
<img :src="imgPath + 'assets/img/user.jpg'" class="img-fluid avatar-40 rounded-circle">
</a>
</li>
</ul>
</div>
</nav>
</div>
</div>
</div>
</div>
</header>
<!-- //login -->
<div
@click="closeLogModal"
:class="{
'metaportal_fn_wallet_closer ready': !logSelector,
'metaportal_fn_wallet_closer ready active': logSelector,
}"
></div>

<div
:class="{
'metaportal_fn_walletbox ready active fullwidth': locSelector,
'metaportal_fn_walletbox ready': !locSelector,
}"
>
<a href="#" @click="closeLogModal" class="fn__closer"><span></span></a>
<div class="walletbox" >
<div class="title_holder">
<h3>SELECT LOCATION</h3>
<p>
Please Select your desired Location
</p>
</div>

<div class="row nopad martop66" v-if="locations.length>0">
<div class="col-md-2 marg15" v-for="loc in locations" :key="loc.loc_id">
<button type="button" :class="getSelected(loc.loc_id)" @click="setLoc(loc.loc_id)" >
{{loc.loc_name}}
</button>
</div>
<div class="clearfix"></div>
</div>


</div></div>

<div
@click="locSelector=!locSelector;"
:class="{
'metaportal_fn_wallet_closer ready': !locSelector,
'metaportal_fn_wallet_closer ready active': locSelector,
}"
></div>

<div
:class="{
'metaportal_fn_walletbox ready active': logSelector,
'metaportal_fn_walletbox ready': !logSelector,
}"
v-if="loginpage&&!forgotpasswrd && !customer.loginCode">
<a href="#" @click="closeLogModal" class="fn__closer"><span></span></a>
<div class="walletbox" >
<div class="title_holder">
<h3>LOGIN HERE!</h3>
<p>
If you are an existing catchmyseat.com member, use your mobile or
email to login.
</p>
</div>

<div class="col-md-12 nopad martop66">
<div class="col-md-12 nopad marbot10">

<div class="col-sm-12 col-xs-12 logor text-center">or</div>
<GoogleLogin :callback="googleCallback" prompt auto-login class="fulwid" v-if="!customer.loginCode" popup-type="TOKEN">
<!-- <button type="button" class="btn btn-primary gobut col-md-12" >
Sign in with Google
</button> -->
</GoogleLogin>
</div>
<div class="clearfix"></div>
</div>
<form class="padd10" name="frmTicket" id="frmTicket" @submit.prevent="loginSubmit">
<input type="hidden" v-model="login.code">
<div>
<label class="tecname padtop14">Mobile or Email *</label>
<input
class="fulwid pad7" type="text" v-model='login.username'
/>
</div>
<div>
<label class="tecname padtop14"> Password * </label>
<input class="fulwid pad7" id="txtCFname" type="password" v-model='login.pwd' />
</div>
<button
type="submit"
class="btn btn-danger btun marg15 nextBtn bk fulwid" :disabled="login.clicked"
>{{login.clicked?"Processing..":"LOGIN"}}</button>
<div id="msgWrap" class="text-warning">{{login.error}}</div>
</form>
<div class="col-xs-12 col-md-12 pal0 nopad">
<a
href="#"
class="text-center forpasw"
@click="frgotnav"
>Forgot Password?
</a>
<p class="reg">
Don't have an account?
<a
class=""
href="#"
@click="NavReg"
>Member Registration</a
>
</p>
</div>
</div></div>
<!-- //login -->

<!--Register-->
<div
:class="{
'metaportal_fn_walletbox ready active': logSelector,
'metaportal_fn_walletbox ready': !logSelector,
}"
v-if="!loginpage">
<div class="metaportal_fn_walletbox ready active" >
<a href="#" @click="closeLogModal" class="fn__closer"><span></span></a>
<div class="walletbox" v-if="!loginpage">
<div class="title_holder">
<h3>MEMBER REGISTRATION</h3>
<p>If not a member, request for the same by filling the following details.</p>
</div>
<form class="padd10" name="frmRegister" id="frmRegister" @submit.prevent="regSubmit()">
<div>
<label class="tecname padtop14">Name  *</label>
<input class="fulwid pad7" type="text" v-model="register.name">
</div> <div>
<label class="tecname padtop14"> Mobile No * </label>
<input class="fulwid pad7" type="text" v-model="register.mobile">
</div> <div>
<label class="tecname padtop14">Email Id *</label>
<input class="fulwid pad7" type="text" v-model="register.email">
</div> 
<button type="submit" class="btn btn-danger marg15 fulwid" :disabled="register.clicked">{{register.clicked?'Processing..':'REGISTER'}}</button>
<div id="msgWrap" v-html="register.msg"></div>
</form>
<div class="col-xs-12 col-md-12 pal0 nopad"><p class="reg " >Already Connected? <a class="" href="#" @click="NavReg">Log In</a> </p> </div>  
</div>
</div>
</div>
<!--Register-->
<!--forgot-->

<div class="metaportal_fn_walletbox ready active" v-if="forgotpasswrd&&loginpage">
<a href="#" @click="closeLogModal" class="fn__closer"><span></span></a>
<div class="walletbox" v-if="forgotpasswrd&&loginpage">
<div class="title_holder">
<h3>FORGOT PASSWORD ?</h3>
<p>Please provide your registered email ID, we will send the reset password to your inbox.</p>
</div>
<form class="padd10" name="frmforgot" id="frmforgot" @submit.prevent="recoverPwd">
<div>
<label class="tecname padtop14">Registered Email ID*</label>
<input class="fulwid pad7" type="text" v-model="forgot.email">
</div> 
<button type="submit" class="btn btn-danger marg15 fulwid" :disabled="forgot.clicked" >{{forgot.clicked?'Processing':'RESET'}}</button>
<div id="msgWrap" v-html="forgot.msg"></div>
</form>
<div class="col-xs-12 col-md-12 pal0 nopad"> <p class="reg " >Don't have an account? <a class="" href="#" @click="NavReg">Member Registration</a> </p> </div>     
</div>
</div>
<!--forgot-->


</template>
<style type="text/css">

header#main-header {
position: fixed;
left: 0em;
right: 0em;
text-align: center;
z-index: 99;
background: #0f2133;
}
li.menu-item.active a {
color:var(--iq-primary);
}
header .navbar-light .navbar-brand img.logo {
width: 11em;
}
.iq-search-bar .search-input {
width: 100%;
height: 2.5em;
padding: 0.313em 0.938em 0.313em 2.5em;
border: none;
border-radius: 0;
color: var(--iq-white);
background: var(--iq-bg1);
}
.iq-search-bar .searchbox .search-link {
position: absolute;
left: 0.938em;
top: 0.375em;
font-size: 1em;
}
.nav-item span.dots {
height: 0.625em;
width: 0.625em;
font-size: 0;
text-align: center;
padding: 0;
position: absolute;
top: 0.188em;
right: 0.75em;
border-radius: 50%;
-webkit-border-radius: 50%;
}
.menu-right .nav-item span.dots {
right: 0;
}
.navbar-right li .iq-sub-dropdown .iq-sub-card {
position: relative;
font-size: inherit;
padding: 0.938em 0.938em;
line-height: normal;
color: inherit;
text-align: left;
display: inline-block;
width: 100%;
border: 0.063em solid;
border-image-slice: 0.063em;
border-width: 0 0 0.063em 0;
border-image-source: linear-gradient(to right, rgba(209, 208, 207, 0.6), rgba(209, 208, 207, 0.3), rgba(209, 208, 207, 0));
}
.iq-sub-card.setting-dropdown {
padding: 0.938em 0.938em 0.938em 0 !important;
}
.navbar-right li .iq-sub-dropdown a.iq-sub-card:last-child {
border-bottom: 0;
}
.notify-bg {
overflow: hidden;
z-index: 99;
}
.notify-bg:before {
content: '';
display: block;
position: absolute;
background: rgba(255, 255, 255, 0.5);
width: 4.375em;
height: 100%;
left: 0;
top: 0;
opacity: 0.5;
filter: blur(1.875em);
transform: translateX(-6.25em) skewX(-30deg);
animation: noti-before-animation 2s infinite 2s;
}
.notify-bg:after {
content: '';
display: block;
position: absolute;
background: rgba(255, 255, 255, 0.2);
width: 1.875em;
height: 100%;
left: 1.875em;
top: 0;
opacity: 0;
filter: blur(0.313em);
transform: translateX(-6.25em) skewX(-30deg);
animation: noti-after-animation 2s infinite 2s;
}
.navbar-right .iq-sub-dropdown .iq-card-body div {
border-radius: 0;
}
.navbar-right .iq-show .iq-sub-dropdown,
.iq-show .search-box {
opacity: 1;
visibility: visible;
transform: translate(0, 0);
}
.navbar-right .iq-sub-dropdown {
width: 18.75em;
opacity: 0;
visibility: hidden;
position: absolute;
top: 100%;
right: 0.938em;
background: var(--iq-bg1);
transform: translate(0, 4.375em);
-webkit-transform: translate(0, 4.375em);
transition: all 0.3s;
padding: 0;
-webkit-box-shadow: 0em 0 1.25em 0 rgba(0, 0, 0, 0.5);
-moz-box-shadow: 0em 0 1.25em 0 rgba(0, 0, 0, 0.5);
box-shadow: 0em 0 1.25em 0 rgba(0, 0, 0, 0.5);
-webkit-transition: all 0.3s ease-out 0s;
-moz-transition: all 0.3s ease-out 0s;
-ms-transition: all 0.3s ease-out 0s;
-o-transition: all 0.3s ease-out 0s;
transition: all 0.3s ease-out 0s;
z-index: 1;
}


/*--------------------------------------------------------------
Navigation
--------------------------------------------------------------*/

header#main-header.menu-sticky {
-webkit-box-shadow: 0em 0em 1.875em 0em rgba(0, 0, 0, 0.4);
-moz-box-shadow: 0em 0em 1.875em 0em rgba(0, 0, 0, 0.4);
box-shadow: 0em 0em 1.875em 0em rgba(0, 0, 0, 0.4);
}
header#main-header.menu-sticky {
position: fixed;
top: 0;
width: 100%;
background: rgba(20, 20, 20, 0.5) !important;
-webkit-box-shadow: 0 0 1.875em 0 rgba(0, 0, 0, .1);
-moz-box-shadow: 0 0 1.875em 0 rgba(0, 0, 0, .1);
box-shadow: 0 0 1.875em 0 rgba(0, 0, 0, .1);
z-index: 100000000;
}
header .navbar .menu-header-menu-container,
header .navbar .menu-all-pages-container,
header .navbar .menu-testing-menu-container,
header .navbar .menu-short-container,
header .navbar .menu-main-menu-container {
display: inline-block;
width: 100%;
}
header .navbar ul.navbar-nav {
display: block;
text-align: center;
}
header .navbar ul li { font-weight: 700 !important;
list-style: none;
margin-right: 1.125em;
position: relative;
transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
-webkit-transition: all 0.3s ease-in-out;
display: inline-block;
}
header .navbar ul li.menu-item a {
font-size: 1em;
font-weight: 400;
position: relative;
padding: 0em 0em 0em 0.625em;
line-height: 4.375em;
position: relative;
z-index: 9;
text-decoration: none;
transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
-webkit-transition: all 0.3s ease-in-out;
text-transform: uppercase;
}
.nav-open .nav-overlay {
visibility: visible;
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
}
.nav-overlay {
left: 0;
right: 0;
top: 0;
bottom: 0;
opacity: 0;
z-index: 900;
display: block;
width: 100%;
position: fixed;
visibility: hidden;
background: rgba(0, 0, 0, 0.6);
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
}
.more-toggle {
color: var(--iq-white) !important;
font-size: 1.375em;
}
.dropdown-toggle.more-toggle::after {
content: none;
}
.mobile-more-menu {
display: none;
}
.more-menu {
position: absolute;
top: 3.125em;
left: auto;
right: 0;
min-width: 18em;
padding: 0.35em 0.5em;
margin: 0;
opacity: 0;
transform: translate(0, 4.375em);
-webkit-transform: translate(0, 4.375em);
-webkit-transition: all 0.3s ease-out 0s;
-moz-transition: all 0.3s ease-out 0s;
-ms-transition: all 0.3s ease-out 0s;
-o-transition: all 0.3s ease-out 0s;
transition: all 0.3s ease-out 0s;
background: var(--iq-bg1);
box-shadow: 0em 0.188em 0.625em rgba(0, 0, 0, 0.75);
visibility: hidden;
z-index: 999;
}
.more-menu.show {
opacity: 1;
transform: translate(0, 0);
visibility: visible;
}
header .navbar ul li:last-child {
margin-right: 0;
}
.navbar ul.navbar-nav>li.menu-item:hover>a, header .navbar ul.navbar-nav>li.menu-item:hover>i, header .navbar ul>li.current-menu-ancestor>a, header .navbar ul>li.current-menu-ancestor>i {
color: var(--iq-primary);
}


.avatar-40 {
height: 2.5em;
width: 4.5em;
min-width: 2.5em;
line-height: 2.5em;
font-size: 0.6em;
}
.rounded-circle {
border-radius: 50% !important;
}.img-fluid {
max-width: 100%;
height: auto;
}
@media screen and (min-width: 1200px) {
header .navbar ul li .sub-menu li a,
header.menu-sticky .navbar ul li .sub-menu li a {
white-space: nowrap;
}
.swiper-banner-button-next, .swiper-banner-button-prev{
display: block;
}
}
@media screen and (max-width: 4000px) and (min-width: 1200px) {
.navbar ul li:hover a,
.navbar ul li:hover i {
color: var(--primary-color);
text-decoration: none;
}
}
@media(max-width:991px) {
.navbar ul.navbar-nav {
overflow-y: scroll;
overflow-x: hidden;
max-height: 21.875em;
display: inline-block;
width: 100%;
}
.navbar-nav {
padding-left: 0em !important;
}
.navbar ul {
/* float: left; */
width: 100%;
background: var(--iq-white-color);
}
header .navbar ul li a,
header .navbar ul li.menu-item a {
padding: 0.938em;
z-index: inherit;
text-align: left;
font-weight: normal;
}
header .navbar ul li.menu-item a {
line-height: normal;
}
header .navbar ul li.menu-item a {
line-height: 0.625em;
float: left;
width: 100%;
text-align: left;
padding: 0.625em 1.875em;
}
header .navbar ul li.menu-item a {
line-height: 0.625em;
float: left;
width: 100%;
text-align: left;
padding: 0.625em 1.875em;
color: var(--iq-white);
}
header .navbar ul li:hover .sub-menu {
box-shadow: none;
}
header .navbar ul li .sub-menu,
header .navbar ul li:hover .sub-menu {
display: none;
position: relative;
top: 0;
width: 100%;
padding: 0em;
z-index: 9;
}
header .navbar ul li.menu-item a {
line-height: normal;
}
header .navbar ul li.menu-item a {
line-height: 0.625em;
float: left;
width: 100%;
text-align: left;
padding: 1.25em 1.25em 1.25em 0.938em;
}
header .navbar ul li:hover .sub-menu {
box-shadow: none;
}
header .navbar ul li:hover .sub-menu {
box-shadow: none;
}
header .navbar ul li .sub-menu li .sub-menu li.menu-item a {
background: transparent;
}
.navbar .navbar-nav li:last-child .sub-menu li:hover>.sub-menu,
.navbar .navbar-nav li:nth-last-child(2) .sub-menu li:hover>.sub-menu {
left: auto;
right: 0em;
top: 0em;
}
header .navbar ul li {
display: block;
}
.mobile-more-menu {
display: block;
}
.menu-right {
display: none;
}
}
@media (max-width: 1024px) {
.container-fluid {
padding: 0 1.875em;
}
.site-main {
padding: 3.125em 0em 3.125em 0em;
}
.iq-genres-section {
padding-bottom: 1.875em;
}
.genres-main {
padding-top: 1.875em;
}
.show-movie-banner-wrapper {
height: 100vh;
}
section.show-movie-section {
padding: 6.25em 0 3.125em;
}
}
@media (max-width: 767px) {
.icon-box-content {
text-align: center;
}
.image-box img {
display: block;
margin-left: auto;
margin-right: auto;
width: 50%;
}
.iq-contact-list {
border: 0em;
}
.image-box svg {
width: 9.375em;
height: 9.375em;
}
.svg-header {
padding-top: 3.125em;
}
.icon-box-content .widget-container .size-default {
font-size: 0.875em;
}
.genres-main {
padding-top: 0.938em;
}
.streamit-reviews .comment-form-author,
.streamit-reviews .comment-form-email {
width: 100%;
}
.container {
max-width:75em;
}
.show-movie .banner-caption{top:1.625em;}
.container-fluid {
padding: 0 0.938em;
}
.tvshows-slider .tv-movie-banner {
margin: 0.625em 0 0.9375em;
}
}
@media (max-width:479px) {
header .navbar ul li.list-inline-item a {
padding-left: 1.25em;
padding-right: 1.25em;
}

header .navbar .iq-usermenu-dropdown>ul>li {
position: static;
}
.search-box {
left: 0;
right: 0;
min-width: 100%;
width: 100%;
}
header .iq-usermenu-dropdown .iq-sub-dropdown {
width: 15.625em;
}
.banner-caption,
.overlay-s-name {
left: 0.938em;
}
.home-slider .swiper-bg {
height: 21.875em;
}
.banner-wrapper .trending-info .trending-dec {
display: none;
}
.banner-wrapper {
height: 60vh;
}
.iq-breadcrumb-two>h2 {
font-size: 1.8em;
}
.trending-pills.nav-pills .nav-item a {
margin: 0 0.625em;
}
.page-numbers li .page-numbers {
height: 2.188em;
width: 2.188em;
line-height: 2.188em;
}
.page-numbers li .page-numbers.current {
height: 2.188em;
width: 2.188em;
}
.iq-pricing-card-two {
padding:1.375em 1.875em 1.875em;
}
li.slide-item .block-images .hover-buttons{
display: none;
}
.iq-view-all {
font-size: 0.75em;
}
li.slide-item:hover .block-description {
animation: none;
opacity: 1;
}
li.slide-item:hover .block-social-info {
animation: none;
opacity: 1;
}
}
</style>
<script>
// import RegistrationModal from '../UserProfile/RegistrationModal.vue';
import axios from "axios";
import { decodeCredential } from "vue3-google-login";
import PWAPromptAndro  from "./PWAPrompt.vue";
import PWAPromptios from 'vue2-ios-pwa-prompt';
//import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component-next";

import { URL } from "@/Conf";
export default {
data: () => ({
locations:{},  
menuOpend:false,
logSelector: false,
isLoading: true,
locSelector:false,
imgPath: process.env.BASE_URL,
FB: {},
loginpage:true,
forgotpasswrd:false,
model: {},
scope: {},
forgot:{clicked:null,email:null,msg:null},
register:{name:null,mobile:null,email:null,clicked:null,msg:null},
login:{code:localStorage.getItem("loginCode"),username:null,pwd:null,error:null},
customer:{
loginCode:localStorage.getItem("loginCode"),
mobile:null,
email:null,
name:null,},
}),
components: {
//VFacebookLoginScope,
PWAPromptAndro,
PWAPromptios
// RegistrationModal
},
computed: {

},

methods: {
menuClicked(){

},
NavReg(){
this.loginpage=!this.loginpage;
if(this.forgotpasswrd==true){
this.forgotpasswrd=false;
}

},
recoverPwd(){
this.register.msg="<div class='text-danger'>";
var valid=true;

if (!this.forgot.email) {
valid=false;
this.forgot.msg+= 'Please enter your Email ID<br>';
} else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.forgot.email)) {
this.forgot.msg+= 'Email ID is not valid<br>';
valid=false;
}
this.register.msg+="</div>";
if(valid)
{
this.forgot.clicked=true;
axios.post(URL + "forgotPwd",this.forgot,{headers: {
'Content-type': 'application/x-www-form-urlencoded',
}}).then((response) => {
this.forgot.clicked=false;
if(response.data.status=="done")
{
this.forgot.msg=response.data.msg;
var that=this;
setTimeout(function() {
that.loginpage=true;
that.forgotpasswrd=false;
that.forgot={email:null,msg:null};
},2000);
}if(response.data.status=="error")
{
this.forgot.msg=response.data.msg;
}
}).catch(()=>{
this.forgot.clicked=false;
this.forgot.msg="<div class='text-danger'>Something went wrong please try again</div>";
})
}
},
regSubmit(){
this.register.msg="<div class='text-danger'>";
var valid=true;
if (!this.register.name) {
valid=false;
this.register.msg+= 'Name cannot be empty<br>';
}
if (!this.register.email) {
valid=false;
this.register.msg+= 'Please enter your Email ID<br>';
} else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.register.email)) {
this.register.msg+= 'Email ID is not valid<br>';
valid=false;
}
const filter = /^\d{10}$/;
if (!filter.test(this.register.mobile)) {
valid=false;
this.register.msg+="Enter a valid 10 digit mobile number (without leading zero and +91)<br>";
}
this.register.msg+="</div>";
if(valid)
{
this.register.clicked=true;
axios.post(URL + "register",this.register,{headers: {
'Content-type': 'application/x-www-form-urlencoded',
}}).then((response) => {
this.register.clicked=false;
if(response.data.status=="done")
{
this.register.msg=response.data.msg;
var that=this;
setTimeout(function() {
that.loginpage=true;
that.register={name:null,email:null,mobile:null,msg:null};
},2000);
}if(response.data.status=="error")
{
this.register.msg=response.data.msg;
}
}).catch(()=>{
this.register.clicked=false;
this.register.msg="<div class='text-danger'>Something went wrong please try again</div>";
})}
},
loginSubmit(){
if(this.login.username && this.login.pwd)
{
this.login.clicked=true;
axios.post(URL + "login",this.login,{headers: {
'Content-type': 'application/x-www-form-urlencoded',
}}).then((response) => {
this.login.clicked=false;
if(response.data.status=="done")
{
this.logSelector=false;
this.customer.loginCode=response.data.customer;
localStorage.setItem("loginCode",response.data.customer.loginCode);
}if(response.data.status=="error")
{
this.login.error=response.data.msg;
}else{
this.login.error="Please check your credentials";
}
}).catch(()=>{

})}else{
this.login.error="Please check your credentials";
}
},
frgotnav(){
this.forgotpasswrd=true;
},
handleSdkInit({ FB, scope }) {
this.FB = FB;
this.scope = scope;
},
fbLogin() {
// this.scope.login();
// let obj=this.FB;
window.FB.login(function(response) {
if (response.authResponse) {
console.log('Welcome!  Fetching your information.... ');

window.FB.api('/me',{fields:'id,email,first_name,last_name'}, function(response) {
console.log(response);
let social={"platform":"fb","id":response.id,"name":response.first_name+" "+response.last_name,"email":response.email}
this.pushSocialData(social);
});
} else {
console.log('User cancelled login or did not fully authorize.');
}
});
},
logout(){
window.FB.logout();
},
googleCallback(response) {
// decodeCredential will retrive the JWT payload from the credential
var social;
if(response.credential)
{
const userData = decodeCredential(response.credential);
social={"platform":"google","id":userData.sub,"name":userData.name,"email":userData.email}
}
if(response.access_token)
{
social={"platform":"google","token":response.access_token}      
}
this.pushSocialData(social);
},
pushSocialData(filds){
axios.post(URL + "socialLogin",filds,{headers: {
'Content-type': 'application/x-www-form-urlencoded',
}}).then((response) => {
this.logSelector=false;
if(response.data.status=="done")
{
this.customer.loginCode=response.data.social;
this.customer.name=response.data.name;
this.customer.email=response.data.email;
this.customer.mobile=response.data.mobile;
localStorage.setItem("loginCode",response.data.social);
}
}).catch(()=>{

})
},
closeLogModal() {
this.logSelector = false;
},
getUser(){
axios
.get(URL + "checkLogin?code="+localStorage.getItem("loginCode"))
.then((response) => {
if(response.data.status=="done"){
this.customer=response.data;
this.$emit('userLoaded');
}
if(response.data.status=="logout"){
this.logSelector=true;
localStorage.removeItem("loginCode");
this.customer.loginCode=null;
}
})
},
getLocations(){
axios
.get(URL + "locations")
.then((response) => {
if(response.data){
this.locations=response.data.data;
this.$emit('locUpdated');
}

})
},
setLoc(myLoc){
localStorage.setItem("locCode",myLoc);
this.locSelector=false;  
this.$emit('locUpdated');
},
getSelected(loc){
  return localStorage.getItem("locCode")==loc?"btn btn-danger fulwid":"btn btn-primary fulwid";
}
},

async created() {
  await this.getLocations();
},
updated() {},
async mounted() {
if (localStorage.getItem("loginCode")) {
this.getUser();
}
if(!localStorage.getItem("locCode")){
this.locSelector=true;  
}
},
props: {},
};
</script>
