import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import 'bootstrap';
import vue3GoogleLogin from 'vue3-google-login';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueGtag from 'vue-gtag'
import { library } from '@fortawesome/fontawesome-svg-core';

import {faLocationPin,faPlay,faChevronRight,faChevronLeft,faArrowDown,faEnvelope,faPhone,faBars,faTicket } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker'
// import 'bootstrap/dist/js/bootstrap.min.js';
const app = createApp(App)

library.add(faFacebook,faInstagram,faTwitter,faLocationPin,faPlay,faChevronRight,faChevronLeft,faArrowDown,faEnvelope,faPhone,faBars,faTicket)
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueGtag,{config: { id: 'G-BR166J9K6P' }});
app.use(vue3GoogleLogin, {
    clientId: '1055512462677-390023s31493s0s6c5ldqr7af2bvnbce.apps.googleusercontent.com'
  })

app.mount('#app');
