<template>
  <section class=" combg explore tabs-fade" data-scroll-index="2">
    <div class="container" style="padding-bottom: 20px">
      <div class="row">
        <div class="col-12 xsnopad10">
          <div class="sec-head-bord mb-10">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 rest flex md-no-flex">
                  <div class="sec-head mr-20 md-mb30 mar15">
                    <h4 style="color: #fff;">Coming soon</h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 xsnopad">
          <div class="tab-content current feat-card" id="tab-1">
            <div class="row" v-if="Results">
              <Carousel v-bind="settings"  :wrapAround="true" :transition="500" :breakpoints="owlbp" :autoplay="900" >
              <Slide  v-for="item in Results"
                  :key="item.title">
                  <div class="item-card">
                    <div class="img">
                      <a href="#0">
                        <img
                          class="bor15"
                          v-if="item.poster"
                          :src="uploads + item.poster"
                          :alt="item.title"
                        />
                      </a>
                    </div>


                    <div class="block-description" style="width: 100%">
                                
                              
                                <div class="hover-buttons" style="padding: 5px; text-align: left;">
                             <span> <span v-if="!item.trailer">{{ item.title }}</span> <a class="font11 venobox"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="item.trailer"
                              :href="item.trailer">{{ item.title }}</a>  <a  role="button" class="btn btn-hover venobox"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="item.trailer"
                              :href="item.trailer">

                              <font-awesome-icon icon="fa-solid fa-play"  aria-hidden="true" />
                                   </a> </span>
                                </div>
                             </div>

                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import venobox from "venobox";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import { URL,UPLOADS } from "../../Conf.js";
import axios from "axios";

export default {

components: {
  Carousel, Slide
  },
  data() {
    return {
      settings: {
      itemsToShow: 1,
      snapAlign: 'start',
    },
    
    Results: null,
      url: URL + "../uploads/",
      uploads:UPLOADS,
      vbox: null,
      owlbp: {
      // 700px and up
      360: {
        itemsToShow: 2.5,
        snapAlign: 'center',
      },

      700: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
    },
      
    };
  },
  methods: {
     async getData() {
      axios.get(URL + "coming-soon/"+localStorage.getItem('locCode')).then((response) => {
        this.Results = response.data.result;
      }).then(()=>{
        new venobox();
      })
    },


  },

  async created() {
    await this.getData();
    

  },
  async mounted() {
  },

};
</script>

<style>
@import "../../../node_modules/venobox";
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__slide {
  padding: 5px;
}
</style>
