<template>
<div :class="!isLoading ? 'metaportal_fn_wallet_closer ready' : ' metaportal_fn_wallet_closer ready active'">
<div class="loaderWraper"><img :src="imgPath + 'assets/img/loader.gif'" width="100"></div>
</div>    
<SiteProgress></SiteProgress>
<SiteHeader ref="headComp" @userLoaded="getUser"></SiteHeader>
<section v-if="!external">
<div :style="['padding: 0px; background: url('+imgPath+'assets/img/thbg.jpg) no-repeat;background-position-x: 0%;background-position-y: 0%; background-size: auto;background-position: center; background-size: cover;']" class="bgblk container-fluid small ">
<section class=" content-section padtb-hed" style="">
<div class="container contbg">
<div class="row" style="z-index: 98;
position: relative;">
<aside class="col-sm-12 col-md-12 col-sm-push-0 sidebar ">
<div class="">
<div class="clearfix"></div>
<div class="col-sm-12 nopad">
<h1 class="bd-hd">{{ TheatreData.sname }} </h1>
<span style="color: #dedede;">{{ TheatreData.overvie }} {{ TheatreData.addr }}</span>
</div>
</div>
</aside>
</div>
</div>
</section>

<section class="feat-card section-padding" style="background-color: #041525;z-index: 98;
position: relative;">
<div class="container">
<div>
<div class="row">
<div class="col-md-12">
<DatePaginator :totalPages="lastPage" :currentPage="currentPage" @pagechanged="onPageChange" :dates="datePages"></DatePaginator>
<div class="ceb-item-style-2" v-for="(mv,index) in dateMovies" :key="index">
<div class="col-md-1 disno"> <img :src="uploads+mv.poster" :alt=" mv.title"> </div>
<div class="col-md-11">
<div class="ceb-infor">
<div><a href="#" style="font-size: 18px;
font-weight: 700;
text-transform: none;">{{ mv.title }}</a> 
<span style="color:#091a2c;font-size:12pcx;padding-bottom:5px;
background-color: #e7f70a;padding: 3px 5px 5px 5px;
margin-left: 7px;border-radius: 5px;"> {{ mv.cert }}</span>
</div>
<span> {{ mv.dur }} </span> 
<div>
<button :class="'text-center tibtn'+(show.isonline?' disabled':'')"  v-for="(show,index) in dateShows[index]" :key="index" @click="gotoShow(TheatreData.link,show.sh_id,show.isonline)"><span
class="linht" style="font-size: 14px;"><span
style="color: #ff2828;font-weight: 500;">{{show.stime}}</span> <br>
<span style="font-size: 10px;color: white;">{{getLang(Languages,show.lang)+" ["+show.diamen+"]"}}</span>
<br>
<span style="font-size: 10px;color: white;">{{show.screen+" "+show.exp}}</span>
</span></button>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



</div>
<SiteFooter></SiteFooter>
</section>
<iframe v-if="external" :src="bookLink" frameborder="0" allow="fullscreen" width="100%" height="100%" @load="isLoading=false"></iframe>  
 <button v-if="external" style="position:absolute;bottom:5px;right:10px;background-color: rgb(255, 88, 0); padding: 9px 25px; border-radius: 20px; text-transform: uppercase; font-size: 13px; cursor: pointer;border:none;color:#fff;" @click="external=false;bookLink:null;">Back to schedule</button>

</template>

<script>
import axios from "axios";
import { UPLOADS, URL } from "@/Conf";
import SiteFooter from "../UI/SiteFooter.vue";
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from "../UI/SiteProgress.vue";
import DatePaginator from "./DatePaginator.vue";

export default {
data: () => ({
imgPath: process.env.BASE_URL,
showModal: false,
modalText: null,
currentTab: 0,
lastPage:0,
MovieDetail:{},
TheatreData:{},
isLoading:false,
external:false,
bookLink:process.env.BASE_URL,
currentPage:0,
datePages:{},
showDates:{},
uploads:UPLOADS,
certclass:{"U":"ce1","U/A":"ce2","A":"ce3"},
Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
days:{"0":"Sun","1":"Mon","2":"Tue","3":"Wed","4":"Thu","5":"Fri","6":"Sat"},
months:{"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"},

showTimes:{},
dateMovies:[],
dateShows:[],


}),

components: {
SiteFooter,
SiteHeader,
SiteProgress,
DatePaginator
},
methods: {
GetMovieDetail(){
axios.get(URL + "theatreMovie/"+this.$route.params.slug).then((response) => {
this.MovieDetail = response.data.result;
this.TheatreData=this.MovieDetail.theatre;
this.showDates=Object.keys(this.MovieDetail.dates);

}).then(()=>{
this.populatePage();
this.onPageChange(this.currentPage);
})

},
gotoShow(lnk,shid,offline){
if(offline){
console.log("not online");
}else{
this.isLoading=true;  
this.bookLink=lnk+shid;
this.external=true;
}
},
populatePage(){
this.datePages=[];
this.lastPage=this.showDates.length;
for(var i=0;i<this.lastPage;i++){
var splited=this.showDates[i].split("-");
var pg={"day":this.days[splited[3]]+" "+splited[2],"mth":this.months[splited[1]]}
this.datePages.push(pg); 
}
},
onPageChange(page) {
this.currentPage = page;
var curdt=this.showDates[page];
this.dateMovies=this.MovieDetail.movies[curdt];
this.dateShows=this.MovieDetail.showtime[curdt];
},

},
computed:{
getLang : ()=>{
return (langs,key) => {
return langs[key]
}

},
}
, mounted() {
this.GetMovieDetail();
}
};
</script>
<style scoped>
iframe {
display: block;
width: 100%;
border: none;
position:absolute;
height:100%;
margin-top:70px;
        }
.mvsingle-item {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
align-items: inherit;
flex-wrap: wrap;
}

.mvsingle-item .col-md-3 vd-item {
margin-bottom: 30px;
width: 170px;
}

.mvsingle-item .vd-it {
position: relative;
}

.mvsingle-item img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
cursor: pointer;
-webkit-transition: all 0.5s ease-out;
-moz-transition: all 0.5s ease-out;
-o-transition: all 0.5s ease-out;
transition: all 0.5s ease-out;
margin-bottom: 15px;
position: relative;
}

.mvsingle-item .vd-it a {
margin: auto;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: center;
-webkit-align-items: center;
-webkit-justify-content: center;
}


.mvsingle-item .vd-infor h6 a {
font-family: 'Dosis', sans-serif;
font-size: 14px;
color: #ffffff;
font-weight: bold;
text-transform: none;
}

.title-hd-sm h4 {
font-family: 'Dosis', sans-serif;
font-size: 14px;
color: #ffffff;
font-weight: bold;
text-transform: uppercase;
}

.title-hd-sm {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
border-bottom: 1px solid #233a50;
padding-bottom: 8px;
margin-top: 30px;
}

.rv-hd {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
}

.rv-hd {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
}

.h3t {
font-family: 'Dosis', sans-serif;
font-size: 18px;
color: #abb7c4;
font-weight: bold;
text-transform: none;
margin-bottom: 15px;
}

.h2t {
font-family: 'Dosis', sans-serif;
font-size: 18px;
color: #fff;
font-weight: bold;
text-transform: none;
font-size: 24px;
margin-bottom: 30px;
}

.mvsingle-item .vd-it a {
margin: auto;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: center;
-webkit-align-items: center;
-webkit-justify-content: center;
}

.mvsingle-item img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
cursor: pointer;
-webkit-transition: all 0.5s ease-out;
-moz-transition: all 0.5s ease-out;
-o-transition: all 0.5s ease-out;
transition: all 0.5s ease-out;
margin-bottom: 15px;
position: relative;
}

.mvsingle-item .vd-it a img {
width: initial;
}

.mvcast-item .cast-it img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
margin-right: 15px;
}

.mvcast-item .cast-it .cast-left {
width: 60%;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: flex-start;
}

.mvcast-item .cast-it {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: flex-start;
margin-bottom: 30px;
}

.title-hd-sm a:hover {
color: #ff5800;
}

.title-hd-sm a {
color: #dd003f;
}

.title-hd-sm {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
border-bottom: 1px solid #233a50;
padding-bottom: 8px;
margin-top: 30px;
}

.movie-single {
color: #dd003f;
}

p.tags a {
background-color: #233a50;
color: #abb7c4;
padding: 2px 5px;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
border-radius: 2px;
margin-right: 4px;
margin-bottom: 5px;
font-size: 11px;
}

.time {
font-family: 'Nunito', sans-serif;
font-size: 12px;
color: #abb7c4;
font-weight: 300;
text-transform: none;
}

.bd-hd {
font-family: 'Dosis', sans-serif;
font-size: 36px;
color: #ffffff;
font-weight: 700;
text-transform: none;
margin-bottom: 10px;
}</style>