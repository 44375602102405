<template>
  <transition name="fade-up-in" appear>
    <div
      v-if="shown"
      class="pwa-prompt"
      :class="[{ shown }]"
    >
      Add Catchmyseat to home screen?

      <button class="install-button" @click="installPWA">
        Install!
      </button>

      <button class="close-button" @click="dismissPrompt">
        <span aria-hidden="true">×</span>
        <!-- Accessible text, so screen readers don't just read a symbol -->
        <span class="sr">Dismiss without installing</span>
      </button>
    </div>
  </transition>
</template>


<script>
import axios from "axios";
import { URL } from "@/Conf";
export default {
  data: () => ({
    installEvent: undefined,
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the banner once the user's clicked
        if (choice.outcome === 'accepted') {
          axios.post(URL + "pwa",this.forgot,{headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              }});
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },

    dismissPrompt() {
      this.shown = false
    }
  }
}
</script>

<style scoped lang="scss">
.pwa-prompt {
  position: fixed;
  font-size: 12px;
  z-index: 2000;
  line-height: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background: #fff;
  color: #05092b;
  transform: translateY(0);
  margin: 0;

  .install-button {
    color:#fff;
    margin: 0 0 0 10px;
    padding:5px 8px;
    background-color: #05092b;
    border: 0;
    border-radius: 4px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: -.25rem;
    font-size: 3rem;
    background: transparent;
    border: 0;
    padding: 0 0.75rem;
    height: 100%;
    line-height: 1;
  }

  .sr {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -100vw;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
}

.fade-up-in-enter-active, .fade-up-in-leave-active {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
}

.fade-up-in-enter, .fade-up-in-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}
</style>