<template>
<SiteHeader></SiteHeader>
<SiteProgress></SiteProgress>
<div style="text-align: center;min-height: 600px;">
<img src="@/../public/assets/img/404.png" alt="Image" class="ggg"><br>
   
</div>
<SiteFooter></SiteFooter>
</template>
<script>

import SiteHeader from './SiteHeader.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteProgress from '../UI/SiteProgress.vue';
export default {
    data() {
        return {
            imgPath: process.env.BASE_URL,
        }
    },
    components:{
       
        SiteHeader,
   SiteFooter,
        SiteProgress
// 
    }
}
</script>
<style scoped>
.ggg{
    width: 45%;
height: auto;
}
</style>