<template>
<div :class="isLoaded ? 'metaportal_fn_wallet_closer ready' : ' metaportal_fn_wallet_closer ready active'">
<div class="loaderWraper">
    <img :src="imgPath + 'assets/img/loader.gif'" width="100">
</div>
</div>
<SiteProgress></SiteProgress>
<SiteHeader ref="headComp" @userLoaded="getUser"></SiteHeader>
<div v-if="movie" :style="['padding: 0px; background: url('+uploads+movie.banner+') no-repeat;background-position-x: 0%;background-position-y: 0%;background-size: auto;background-position: center;background-size: cover;']" class="bgblk container-fluid small "  >
<section  class=" content-section padtb-hed" style="" >
<div class="container contbg">
<div class="row" style="z-index: 98;
position: relative;">
<div class="col-sm-4 col-md-4">
<div class="col-md-12 nopad">
<div class="movie-img sticky-sb" style="top: 0px;">
<img v-if="movie.poster" :src="uploads+movie.poster" :alt="movie.title">
</div>
</div>
</div>
<aside class="col-sm-8 col-md-8 col-sm-push-0 sidebar ">
<div class="">
<div class="clearfix"></div>
<div class="col-sm-12 nopad">

<h1 class="bd-hd">{{movie.title}}</h1>
<div><div class="btn-transform transform-vertical" style="width: 27%;">
<div>
<router-link :to="{ name: 'mvtheatre', params: {slug: $route.params.slug}}" style="color: #020d18 !important;margin-bottom: 10px;" href="#" class="item item-1 yellowbtn"> <i class="ion-card"></i>Book Tickets</router-link>
 </div>
</div> </div>


<div class="tabbable-panel">
<div class="tabbable-line">
<ul class="nav nav-tabs">
<li :class="currentTab == 0 ? 'active' : ''">
<a href="#" @click="currentTab = 0" class="fon17 padsmri-le">
Overview
</a>
</li>
<li :class="currentTab == 1 ? 'active' : ''">
<a href="#" @click="currentTab = 1" class="fon17 padsmri-le">
Cast </a>
</li>
<li :class="currentTab == 2 ? 'active' : ''">
<a href="#" @click="currentTab = 2" class="fon17 padsmri-le">
Media
</a>
</li>
</ul>
<div class="tab-content">
<div :class="currentTab == 0 ? 'tab-pane active' : 'tab-pane'">
<div class="row">
<div class="col-md-8 col-sm-12 col-xs-12">
<div style="font-size: 22px;padding-bottom: 10px;color: aliceblue;">
Release Date: {{movie.dt}} </div>

<p>{{movie.description}}</p>


<div class="title-hd-sm">
<h4>cast</h4>
<a href="#" class="time">Full Cast & Crew <i
class="ion-ios-arrow-right"></i></a>
</div>
<!-- movie cast -->
<div class="mvcast-item" v-if="cast">
<div class="cast-it" v-for="(cst,key) in cast" :key="key">
<div class="cast-left">
<img v-if="cst.img" style="width: 12%;"
:src="uploads+cst.img"
alt="">
<a >{{cst.name}}</a>
</div>
</div>
</div>

</div>
<div class="col-md-4 col-xs-12 col-sm-12  ">
<div class="sb-it">
<h6 class="movie-single">Director: </h6>
<p><a >{{movie.dir}}</a></p>
</div>
<div class="sb-it">
<h6 class="movie-single">Writer: </h6>
<p><a >{{movie.writer}}</a> </p>
</div>
<div class="sb-it">
<h6 class="movie-single"> Produced by: </h6>
<p><a >{{movie.prod}}</a> </p>
</div>
<div class="sb-it">
<h6 class="movie-single">Stars: </h6>
<p>{{getCastNames(cast)}}</p>
</div>

<div class="sb-it">
<h6 class="movie-single">Release Date:</h6>
<p>{{movie.dt}}</p>
</div>
<div class="sb-it">
<h6 class="movie-single">Run Time:</h6>
<p>{{movie.dur}} Minutes</p>
</div>

<div class="sb-it">
<h6 class="movie-single">Genres:</h6>
<p class="tags">
<span class="time" v-for="gen in getGenre(movie.genre)" :key="gen"><a>{{gen}}</a></span>

</p>
</div>

</div>
</div>

</div>

<div :class="currentTab == 1 ? 'tab-pane active' : 'tab-pane'">

<div class="row" v-if="cast">
<h2 class="h2t">Cast</h2>
<div class="mvcast-item">

<div class="cast-it" v-for="(cst,key) in cast" :key="key">
<div class="cast-left">
<img v-if="cst.img" style="width: 12%;"
:src="uploads+cst.img" :alt="cst.name">
<a href="#">{{cst.name}}</a>
</div>
</div>
</div>


</div>

</div>

<div :class="currentTab == 2 ? 'tab-pane active' : 'tab-pane'">
<div >



<div class="row" v-if="media">
<div class="rv-hd">
<div>
<h3 class="h3t">Videos & Photos of</h3>
<h2 class="h2t">{{movie.title}}</h2>
</div>
</div>
<div class="title-hd-sm">
<h4>Videos <span>({{media.length}})</span></h4>
</div>
<div class="row mvsingle-item media-item">

<div class="col-md-3 vd-item" v-for="(med,mindex) in media" :key="mindex">
<div class="vd-it">
    <img class="vd-img" :src="getThumb(med)" >
<a class="font11 venobox" data-autoplay="true" data-vbtype="video" :href="med">
    <img :src="imgPath+'assets/img/play-vd.png'" alt="">
</a>  

</div>
<!-- <div class="vd-infor">
<h6> <a href="#">Trailer: Watch New Scenes</a></h6>
<p class="time"> 1: 31</p>
</div> -->
</div>


</div>


</div>


</div>

</div>
</div>
</div>
</div>
</div>
</div>
</aside>
</div>
</div>

<!-- end container -->
</section>
</div>

<SiteFooter></SiteFooter>

<modal-video/>
</template>

<script>
import axios from "axios";
import { URL,UPLOADS } from "@/Conf";
import SiteFooter from "../UI/SiteFooter.vue";
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from "../UI/SiteProgress.vue";
//import ModalVideo from '../UI/ModalVideo.vue'
import venobox from "venobox";

export default {
data: () => ({
imgPath: process.env.BASE_URL,
isLoaded:true,
currentTab: 0,
movie:null,
media:null,
cast:null,
uploads:UPLOADS,
videoIsOpen: false,
}),

components: {
SiteFooter,
SiteHeader,
SiteProgress,
},
methods: {
getMovieDetails() {
axios.get(URL + "movie/" + this.$route.params.slug).then((response) => {
if (response.data.status == "200") {
/**/
 this.movie = response.data.result.movie;
 this.cast = response.data.result.cast;
 this.media = response.data.result.media;
 this.isLoaded=true;
}
}).then(()=>{
 new venobox();

});
},
openVideo() {
this.videoIsOpen = !this.videoIsOpen
},
}, 
computed:{
getCastNames:()=>{
       return  (castar)=>{
return  castar.map((el)=>{
    return el.name
}).join(", ");
}
},
getGenre:()=>{
    return (gen)=>{
return gen.split(",");
    }
},
getThumb:()=>{
    return(vdurl)=>{
       const rex= vdurl.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

        //let video   = (results === null) ? vdurl : results[1];
        console.log(rex[2])
        //console.log(results)
        return 'http://img.youtube.com/vi/' + rex[2] + '/0.jpg';
        }     
}
},
mounted() {
this.getMovieDetails();
}
};
</script>
<style>
@import "../../../node_modules/venobox";

.mvsingle-item {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
align-items: inherit;
flex-wrap: wrap;
}
.mvsingle-item .col-md-3 vd-item {
margin-bottom: 30px;
width: 170px;
}
.mvsingle-item .vd-it {
position: relative;
}
.mvsingle-item img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
cursor: pointer;
-webkit-transition: all 0.5s ease-out;
-moz-transition: all 0.5s ease-out;
-o-transition: all 0.5s ease-out;
transition: all 0.5s ease-out;
margin-bottom: 15px;
position: relative;
}
.mvsingle-item .vd-it a {
margin: auto;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: center;
-webkit-align-items: center;
-webkit-justify-content: center;
}


.mvsingle-item .vd-infor h6 a {
font-family: 'Dosis', sans-serif;
font-size: 14px;
color: #ffffff;
font-weight: bold;
text-transform: none;
}
.title-hd-sm h4 {
font-family: 'Dosis', sans-serif;
font-size: 14px;
color: #ffffff;
font-weight: bold;
text-transform: uppercase;
}.title-hd-sm {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
border-bottom: 1px solid #233a50;
padding-bottom: 8px;
margin-top: 30px;
}.rv-hd {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
}.rv-hd {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
}
.h3t{
font-family: 'Dosis', sans-serif;
font-size: 18px;
color: #abb7c4;
font-weight: bold;
text-transform: none;
margin-bottom: 15px;
}
.h2t {
font-family: 'Dosis', sans-serif;
font-size: 18px;
color: #fff;
font-weight: bold;
text-transform: none;
font-size: 24px;
margin-bottom: 30px;
}.mvsingle-item .vd-it a {
margin: auto;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: center;
-webkit-align-items: center;
-webkit-justify-content: center;
}.mvsingle-item img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
cursor: pointer;
-webkit-transition: all 0.5s ease-out;
-moz-transition: all 0.5s ease-out;
-o-transition: all 0.5s ease-out;
transition: all 0.5s ease-out;
margin-bottom: 15px;
position: relative;
}.mvsingle-item .vd-it a img {
width: initial;
}.mvcast-item .cast-it img {
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
margin-right: 15px;
}.mvcast-item .cast-it .cast-left {
width: 60%;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: flex-start;
}.mvcast-item .cast-it {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: flex-start;
margin-bottom: 30px;
}
.title-hd-sm a:hover {
color: #ff5800;
}.title-hd-sm a {
color: #dd003f;
}.title-hd-sm {
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
border-bottom: 1px solid #233a50;
padding-bottom: 8px;
margin-top: 30px;
}
.movie-single  {
color: #dd003f;
}
p.tags a {
background-color: #233a50;
color: #abb7c4;
padding: 2px 5px;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
border-radius: 2px;margin-right: 4px;
margin-bottom: 5px;
font-size: 11px;
}.time {
font-family: 'Nunito', sans-serif;
font-size: 12px;
color: #abb7c4;
font-weight: 300;
text-transform: none;
}.bd-hd {
font-family: 'Dosis', sans-serif;
font-size: 36px;
color: #ffffff;
font-weight: 700;
text-transform: none;
margin-bottom: 10px;
}
</style>