<template>
  <section class=" combg explore tabs-fade nopad" data-scroll-index="0" style="
  background: url('assets/img/slider-bg.jpg') no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
background-position: center;
text-align: center;
background-size: cover;
position: relative;" >
<div class="container padtopb" >
     <div class="row">
      <div class="col-12 xsnopad ">     
        <div class="top-search banner" >
<select @change="setLoc($event)">
<option value="">Select Location</option>
<option v-for="loc in locations" :key="loc.loc_id" :value="loc.loc_id" :selected="isSelected(loc.loc_id)">{{loc.loc_name}}</option>
</select>
<input type="text" placeholder="Search for a Movie, Event Show or celebrity that you are looking for">
</div>  
    </div>  
      
        <div class="col-12 xspad15 text-end sol" > Follow us : 
          <span class="icons"> 
            <span style="padding-right: 10px;"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/></span> 
            <span style="padding-right: 10px;"> <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/> </span>
            <span style=""> <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> </span>
          
          </span>
        </div>  </div>

      <div class="row">
        <div class="col-12 xsnopad">
          <div class="tab-content current feat-card" id="tab-1">
            <div class="row" v-if="Results">
              <Carousel :wrapAround="true" :transition="500" :breakpoints="owlbp" :autoplay="0" >
              <Slide  v-for="item in Results"
                  :key="item.title">
                
                  <div class="item-card">
                    <div class=" poster">
                        <img
                          class=""
                          v-if="item.poster"
                          :src="uploads + item.poster"
                          :alt="item.title"
                          @click="$router.push('/movie/'+item.slug)" style="cursor:pointer;"
                        />
                        <div class="fav2">

                         <div class="cate"><span class="green">{{ item.genre }}</span></div>

                          <span class="bgl" ><a href="#" class="font11 banm" style="font-size: 13px;" @click="$router.push('/movie/'+item.slug)">{{StripName(item.title)}}</a></span></div>
                    </div>


                    

                  </div>
               
              </Slide>
              <template >
      <Pagination />
      <Navigation />
    </template>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
<script>

import venobox from "venobox";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'

import { URL,UPLOADS } from "../../Conf.js";
import axios from "axios";

export default {

components: {
  Carousel, Navigation, Slide, Pagination
  },
  data() {
    return {
      Results: null,
      locations:{},
      url: URL + "../uploads/",
      imgPath: process.env.BASE_URL,
      uploads:UPLOADS,
      vbox: null,
      owlbp: {
      // 700px and up
      300: {
        itemsToShow: 3,
        snapAlign: 'center',
      },

      700: {
        itemsToShow: 4,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
    },
      
    };
  },
  methods: {
     async getData() {
      axios.get(URL + "movies/"+localStorage.getItem('locCode')).then((response) => {
        this.Results = response.data.result;
        this.$emit("axloaded");
      }).then(()=>{
        new venobox();
      })
    },
    
isSelected(myloc){
return myloc==localStorage.getItem('locCode');
},
setLoc(item){
localStorage.setItem("locCode",item.target.value);
this.$emit('locUpdated');
},

  },
computed:{
  StripName : ()=>{
            return (MovieTitle) => {
            return MovieTitle.length>17?MovieTitle.substr(0,17)+"..":MovieTitle;
        }

      }
   
},
  async created() {
    await this.getData();

  },
  async mounted() {
  },

};
</script>

<style>
@import "../../../node_modules/venobox";
</style>
